import { apiPaths } from "./environmentAPIPaths";

let baseUrl = 'https://rdb-api-prod.warnerbros.com/';

export const environment = {
  production: true,
  VERSION: require("package.json").version,
  environment: "prod",
  baseHref: "/prod",
  baseUrl: baseUrl,
  oktaConfig: {
    clientId: "0oaqdgipzelp9Keff0x7",
    issuer: "https://sso.wbd.com",
    redirectUri: "https://azkaban.warnermedia.com/implicit/callback",
    scope: "openid profile email groups",
    authorizeUrl: "https://sso.wbd.com/oauth2/v1/authorize",
    userinfoUrl: "https://sso.wbd.com/oauth2/v1/userinfo",
    tokenUrl: "https://sso.wbd.com/oauth2/v1/token",
    pkce: false,
    logoutURL: "https://sso.wbd.com/oauth2/v1/logout",
  },

  getAPI: (
    apiName: string,
    prefixURL = "https://rdb-api-prod.warnerbros.com/" // change the base api URL
  ): string => {
    return prefixURL + apiPaths[apiName];
  },
};
