import { Component, OnInit } from "@angular/core";
import { UserService } from "../services/user.service";
import { Router } from "@angular/router";
import { OktaAuthService } from "@okta/okta-angular";
import { SpinnerService } from "../services/spinner.service";
import { NotificationService } from "../services/notification.service";
import { AuthService } from "../services/auth.service";
import { HttpErrorResponse } from "@angular/common/http";
import { SearchService } from "../services/search.service";
import { UserActionService } from "../services/user-action.service";

@Component({
  selector: "app-default-redirect",
  templateUrl: "./default-redirect.component.html",
  styleUrls: ["./default-redirect.component.css"],
})
export class DefaultRedirectComponent implements OnInit {
  constructor(
    private userService: UserService,
    private router: Router,
    private oktaAuthService: OktaAuthService,
    private spinnerService: SpinnerService,
    private notificationService: NotificationService,
    private authService: AuthService,
    private searchService: SearchService,
    private userActionService: UserActionService
  ) {
    this.getUser();
  }

  ngOnInit(): void {}

  private async getUser() {
    this.spinnerService.setIsLoading(true);

    const res = await this.oktaAuthService.getUser();
    this.userService.firstName = res.given_name;
    this.userService.middleName = null;
    this.userService.lastName = res.family_name;
    this.userService.userEmail = res.email;

    this.userService.setRegisteredUserGroups(res.groups);
    this.userService.setOktaUserInfo(res);

    if (res.groups.length > 0) {
      this.userService
        .userLoginAPI()
        .then(() => {
          this.spinnerService.setIsLoading(false);

          localStorage["isUserRegistered"] = true;
          this.searchService.selectedApplicationID = this.userService.userApplications[0].ApplicationID;

          // log the default selected user application
          const userApplications = this.userService.userApplications;

          const selectedApp = this.userService.userApplications[0].ApplicationID;
          const newAppName = this.userService.userApplications[0].Name;
          const route = this.userService.userApplications[0].Route

          // log it into user actions
          this.userActionService.recordUserActionAPI(
            "Application Change",
            `Changed From: ${undefined} (${undefined}) To: ${newAppName} (${selectedApp})`
          );

          // route user to application
          this.router.navigate([route]);
        })
        .catch((err: HttpErrorResponse) => {
          this.spinnerService.setIsLoading(false);
          this.notificationService.failure(err.message);
          this.authService.logout();
        });
    } else {
      this.notificationService.failure(
        "You are not assigned to any of the required AD Groups."
      );
      this.authService.logout();
    }
  }
}
