import { Component, OnInit, Inject, DoCheck } from "@angular/core";

import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material";
import { PersonOfInterest } from "src/app/models/person-of-interest.model";
import { DialogService } from "src/app/services/dialog.service";
import { PickList } from "src/app/models/pickList.model";
import { GridApi } from "ag-grid-community";
import { RequiredFieldsService } from "src/app/services/required-fields.service";
import { PersonOfInterestService } from "src/app/services/person-of-interest.service";
import { CommonService } from "src/app/services/common.service";
import { NotificationService } from "src/app/services/notification.service";
import { Base64Service } from "src/app/services/base64.service";
import { UpdatePersonOfInterestModalComponent } from "../update-person-of-interest/update-person-of-interest-modal.component";
import { SearchService } from 'src/app/services/search.service';
import { Address } from "cluster";
import { Phone } from "src/app/models/phone.model";
import { Attachments } from "src/app/models/attachments.model";
import { Notes } from "src/app/models/note.model";
import { DatePipe } from "@angular/common";
import { ButtonRendererComponent } from "src/app/renderer/button-renderer.component";
import { HyperLinkComponent } from "src/app/hyperlink.component";
import { SpinnerService } from "src/app/services/spinner.service";

@Component({
  selector: "app-view-person-of-interest-modal",
  templateUrl: "./view-person-of-interest-modal.component.html",
  styleUrls: ["./view-person-of-interest-modal.component.css"],
})
export class ViewPersonOfInterestModalComponent implements OnInit {
  protected step = 0;
  protected personOfInterest: PersonOfInterest;
  protected genderList: PickList[];
  protected restrictedReasonList: PickList[];
  protected restrictedTypeList: PickList[];
  protected raceList: PickList[];
  protected hairList: PickList[];
  protected eyesList: PickList[];

  protected addedAliases: Array<String> = [];
  protected alias: string;
  protected restricted: boolean = false;
  protected isRestrictedReasonloading: boolean = false;
  protected isGenderLoading: boolean = false;
  protected gridApi: GridApi;
  protected defaultImageIndex = undefined;
  protected maxDate;
  protected height: string;
  protected SignedURL: string;
  protected addressRowData: Array<Address> = [];
  protected eventRowData: Array<Event> = [];
  protected notesRowData: Array<Notes> = [];
  protected notesGridOptions: Object;
  protected rowClassRulesNotes;
  protected rowClassRules;
  attachmentArray: Array<Attachments> = [];
  protected rowData: Array<Attachments> = [];
  protected poiData: any;
  protected selectedRegions: any = [];
  protected attachmentGridApi: GridApi;
  protected addressGridApi: GridApi;
  protected eventGridApi: GridApi;
  frameworkComponents: any;

  protected monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  protected signedURL: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PersonOfInterest,
    protected dialogRef: MatDialogRef<ViewPersonOfInterestModalComponent>,
    protected dialog: MatDialog,
    protected requiredFieldService: RequiredFieldsService,
    protected personOfInterestService: PersonOfInterestService,
    protected commonService: CommonService,
    protected dialogService: DialogService,
    protected notificationService: NotificationService,
    protected base64Service: Base64Service,
    protected searchService: SearchService,
    protected datePipe: DatePipe,
    protected spinnerService: SpinnerService,
  ) {
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
      hyperLinkComponent: HyperLinkComponent,
    };
  }

  addressColumnDefs = [


    {
      headerName: "",
      checkboxSelection: true,
      cellStyle: { color: "#000" },
      hide: false,
      sortable: true,
      resizable: true,
      width: 30,
    },

    {
      headerName: "Address Type",
      field: "AddressType",
      cellStyle: { color: "#000" },
      hide: false,
      sortable: true,
      cellRenderer: (data) => {
        if (data) {
          let addressType = this.commonService.getPickListData(
            this.searchService.getTableName(),
            "AddressType"
          ).filter((e) => e.PickListID == +data.value)
            .map((e) => e.DisplayValue)[0];
          return addressType ? addressType : null;
        }
      },
      resizable: true,
      width: 100,
    },
    {
      headerName: "Default",
      field: "Default",
      resizable: true,
      cellRenderer: (data) => {
        if (data.value) {
          return "Yes";
        } else {
          return "No";
        }
      },
      width: 70,
    },
    {
      headerName: "Address",
      field: "Address",
      cellStyle: { color: "#000" },
      hide: false,
      sortable: true,
      resizable: true,
      width: 467,
    },
    {
      headerName: "Address 2",
      field: "Address2",
      cellStyle: { color: "#000" },
      hide: false,
      sortable: true,
      resizable: true,
      width: 467,
    },
    {
      headerName: "Country",
      field: "Country",
      hide: false,
      sortable: true,
      resizable: true,
      cellRenderer: (data) => {
        if (data) {
          let addressCountry = this.commonService.getPickListData(
            this.searchService.getTableName(),
            "Country"
          ).filter((e) => e.PickListID == +data.value)
            .map((e) => e.DisplayValue)[0];
          return addressCountry ? addressCountry : null;
        }
      },
      width: 100,
    },

    {
      headerName: "State",
      field: "State",
      hide: false,
      sortable: true,
      resizable: true,
      cellRenderer: (data) => {
        if (data) {
          let addressState = this.commonService.getPickListData(
            this.searchService.getTableName(),
            "State"
          ).filter((e) => e.PickListID == +data.value)
            .map((e) => e.DisplayValue)[0];
          return addressState ? addressState : null;
        }
      },
      width: 100,
    },


    {
      headerName: "City",
      field: "City",
      hide: false,
      sortable: true,
      resizable: true,
      cellRenderer: (data) => {
        if (data) {
          let addressCity = this.commonService.getPickListData(
            this.searchService.getTableName(),
            "City"
          ).filter((e) => e.PickListID == +data.value)
            .map((e) => e.DisplayValue)[0];
          return addressCity ? addressCity : null;
        }
      },
      width: 100,
    },

    {
      headerName: "Zip",
      field: "Zip",
      hide: false,
      sortable: true,
      resizable: true,
      cellRenderer: (data) => {
        if (data) {
          let addressZip = this.commonService.getPickListData(
            this.searchService.getTableName(),
            "Zip"
          ).filter((e) => e.PickListID == +data.value)
            .map((e) => e.DisplayValue)[0];
          return addressZip ? addressZip : null;
        }
      },
      width: 100,
    },

  ];
  eventColumnDefs = [
    {
      headerName: "Notes Type",
      field: "Type",
      checkboxSelection: true,
      cellStyle: { color: "#000" },
      hide: false,
      sortable: true,
      resizable: true,
      cellRenderer: (data) => {
        if (data) {
          let eventType = this.commonService.getPickListData(
            this.searchService.getTableName(),
            "EventType"
          ).filter((e) => e.PickListID == +data.value)
            .map((e) => e.DisplayValue)[0];
          return eventType ? eventType : null;
        }
      },
      width: 100,
    },

    {
      headerName: "Site",
      field: "SiteID",
      cellStyle: { color: "#000" },
      hide: false,
      sortable: true,
      cellRenderer: (data) => {
        if (data) {
          let addressType = this.commonService.siteList.filter((e) => e.SiteID == +data.value)
            .map((e) => e.Name)[0];
          return addressType ? addressType : null;
        }
      },
      resizable: true,
      width: 100,
    },
    {
      headerName: "Description",
      field: "Description",
      cellStyle: { color: "#000" },
      hide: false,
      sortable: true,
      resizable: true,
      width: 100,
    },
    {
      headerName: "Note",
      field: "POINoteEvent",
      cellStyle: { color: "#000" },
      hide: false,
      sortable: true,
      wrapText: true,
      autoHeight: true,
      cellRenderer: (data) => {
        const formattedDate = data.value[0].Note
        return formattedDate ? formattedDate : null;
      },
      resizable: true,
      width: 467,
    },
    {
      headerName: "Start Time",
      field: "StartTime",
      hide: false,
      cellRenderer: (data) => {
        const formattedDate = this.datePipe.transform(data.value, "MM/dd/yyyy");
        return formattedDate ? formattedDate : null;
      },
      sortable: true,
      resizable: true,
      width: 100,
    },
    {
      headerName: "End Time",
      field: "EndTime",
      hide: false,
      cellRenderer: (data) => {
        const formattedDate = this.datePipe.transform(data.value, "MM/dd/yyyy");
        return formattedDate ? formattedDate : null;
      },
      sortable: true,
      resizable: true,
      width: 100,
    },

  ];

  ngOnInit(): void {
    this.spinnerService.setIsLoading(true);
    this.commonService.getSiteListApi();
    this.personOfInterest = { ...this.data };
    this.personOfInterestService.getPersonOfInterest(this.personOfInterest.PersonOfInterestID).then(() => {
      this.poiData = this.personOfInterestService.personOfInterest[0];
      this.rowData = this.poiData.AttachmentsArray;
      this.attachmentArray = this.poiData.AttachmentsArray;
      this.addressRowData = this.poiData.POIAddress;
      this.eventRowData = this.poiData.POIEvent;
      this.spinnerService.setIsLoading(false);
      if (this.personOfInterest.PhotosArray.length > 0 || this.personOfInterest.AttachmentsArray.length > 0) {
        this.commonService.getAllFiles(this.personOfInterest.PhotosArray.filter((f) => f.Active), this.personOfInterest.AttachmentsArray.filter((f) => f.Active), this.personOfInterest.PersonOfInterestID)
      }
    })
      .catch((err) => {
        this.notificationService.failure(err.msg);
      });

    this.personOfInterestService.getPOIRegion(this.personOfInterest.RecordID).then((result) => {
      this.selectedRegions = result.filter((f) => f.Active);
    });
    if (
      this.commonService.pickList != undefined &&
      this.commonService.pickList.filter((f) => f.TableName === this.searchService.getPageName()).length > 0
    ) {
      this.genderList = this.commonService.getPickListData(
        "PersonOfInterest",
        "Gender"
      );
      this.raceList = this.commonService.getPickListData(
        "PersonOfInterest",
        "Race"
      );
      this.hairList = this.commonService.getPickListData(
        "PersonOfInterest",
        "Hair"
      );
      this.eyesList = this.commonService.getPickListData(
        "PersonOfInterest",
        "Eyes"
      );
      this.restrictedReasonList = this.commonService.getPickListData(
        "PersonOfInterest",
        "RestrictedReason"
      );
      this.restrictedTypeList = this.commonService.getPickListData(
        "PersonOfInterest",
        "RestrictionType"
      );
    } else {
      this.commonService
        .getPickListApi()
        .then(() => {
          if (
            this.commonService.pickList != undefined &&
            this.commonService.pickList.length > 0
          ) {
            this.genderList = this.commonService.getPickListData(
              "PersonOfInterest",
              "Gender"
            );
            this.raceList = this.commonService.getPickListData(
              "PersonOfInterest",
              "Race"
            );
            this.restrictedReasonList = this.commonService.getPickListData(
              "PersonOfInterest",
              "RestrictedReason"
            );
            this.restrictedTypeList = this.commonService.getPickListData(
              "PersonOfInterest",
              "RestrictionType"
            );
          }
        })
        .catch((err) => {
          this.notificationService.failure(err);
        });
    }

    // calculating height
    // var realFeet = ((this.data.Height*0.393700) / 12);      
    // var feet = Math.floor(realFeet);
    // var inches = Math.round((realFeet - feet) * 12);
    // this.height =
    // feet +
    //   "' " +
    //   (+inches) +
    //   '"';
  }
  columnDefs = [
    {
      headerName: "Name",
      field: "LogicalFileName",
      cellStyle: { color: "#000" },
      hide: false,
      sortable: true,
      resizable: true,
      // cellRenderer: "hyperLinkComponent",
      width: 200,
    },
    {
      headerName: "Description",
      field: "Description",
      cellStyle: { color: "#000" },
      hide: false,
      sortable: true,
      editable: true,
      resizable: true,
      width: 567,
    },
    {
      headerName: "",
      cellRenderer: "hyperLinkComponent",
      resizable: true,
      cellRendererParams: {
        onClick: this.onBtnClick.bind(this),
        label: "Download",
      },
      width: 100,
    }
  ];
  onBtnClick(e) {
    //console.log("button Clicked");
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setStep(index: number): void {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  //get grid
  onAttachmentGridReady(params) {
    this.attachmentGridApi = params.api;
  }

  onAddressGridReady(params) {
    this.addressGridApi = params.api;
  }
  onEventGridReady(params) {
    this.eventGridApi = params.api;
  }
  onRowSelected(params) {
    var slctdData = this.attachmentGridApi.getSelectedRows();
  }
  //close
  close() {
    this.onNoClick();
  }
  //opens edit modal, send row data to modal
  updatePoi() {
    this.onNoClick();
    this.dialog.open(UpdatePersonOfInterestModalComponent, {
      data: this.personOfInterest,
    });
  }
  panelExpanded() { }
}
