import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { MatDialog, MatDialogConfig, MatSlideToggle } from "@angular/material";
import { NewRecordComponent } from "../new-record/new-record.component";
import { EditRecordComponent } from "../edit-record/edit-record.component";
import * as _moment from "moment";
import { default as _rollupMoment } from "moment";
import { Router } from "@angular/router";
import { PersonOfInterest } from "../models/person-of-interest.model";
import { AuthService } from "../services/auth.service";
import { SearchService } from "../services/search.service";
import { UpdateDriverModalComponent } from "./modals/update-driver/update-driver-modal.component";
import { CreateDriverModalComponent } from "./modals/create-driver/create-driver-modal.component";
import { ViewPersonOfInterestModalComponent } from "../person-of-interest/modals/view-person-of-interest/view-person-of-interest-modal.component";
import { ProfileRenderer } from "../renderer/profile-renderer.component";
import { Driver } from "../models/driver.model ";
import { ViewDriverModalComponent } from "./modals/view-driver/view-driver-modal.component";
import { UserService } from "../services/user.service";
import { Photos } from '../models/photo.model';
import { CommonService } from '../services/common.service';
import { NotificationService } from '../services/notification.service';
import { Attachments } from '../models/attachments.model';
import { DriverService } from '../services/driver.service';
import { DatePipe } from '@angular/common';
import { DialogService } from '../services/dialog.service';
import { SearchFilterModalComponent } from '../search-filter/search-filter.modal.component';
import { SpinnerService } from "../services/spinner.service";
import { PickList } from '../models/pickList.model';
const moment = _rollupMoment || _moment;

@Component({
  selector: "app-driver",
  templateUrl: "./driver.component.html",
  styleUrls: ["./driver.component.css"],
})
export class DriverComponent implements OnInit {
  rowData: any;
  data: any;
  params: [];
  private gridApi;
  public defaultColDef : object;
  gridOptions: Object;
  protected selectedApp: number;
  protected isRowSelected: boolean = false;
  protected selectedDriver: Driver;
  protected clickedDriver: Driver;
  protected localGroupingList: PickList[];
  protected statusList: PickList[];
  protected certificationList: any[] = [];
  protected selfCertificationlist: any;
  protected userApplications: Array<{
    ApplicationID: number;
    Name: string;
    Route: string;
  }>;
  frameworkComponents: any;
  @ViewChild("searchField") searchField: ElementRef;
  currentDate = moment(new Date()).format("YYYY-MM-DD");
  lastYearSameDate = moment(this.currentDate).subtract(1, 'year').format('YYYY-MM-DD');
  protected isActive: boolean = true;
  protected driver: Driver;
  protected driverLoaded: boolean = false;
  activeStatusPickListId: any;
  inactiveStatusPickListId: any;
  inactiveStatusID: number;
  activeStatusID: number;
  photoArray: Photos[] = [];
  protected newPhotoArray: Photos[] = [];
  protected newAttachmentArray: Attachments[] = [];
  attachmentArray: Array<Attachments> = [];
  @ViewChild('toggle') toggle: MatSlideToggle;
  //ag-grid
  columnDefs = [
    {
    headerName: "Personal Information",
    children: [
      {
        cellRenderer: "profileRenderer",
        autoHeight: true,
        checkboxSelection: true,
        width: 120,
        cellStyle: { color: "#ffff" },
      },
      {
        headerName: "Resource Code",
        field: "ResourceCode",
        width: 110,
        cellStyle: { color: "#ffff",'text-transform': 'uppercase' },
        hide: "true"
      },
      {
        headerName: "RecordID",
        field: "RecordID",
        cellStyle: { color: "#ffff",'text-transform': 'uppercase' },
        hide: "true",
        sortable: true,

      },
      {
        headerName: "Group Code",
        field: "GroupCode",
        width: 110,
        cellStyle: { color: "#ffff",'text-transform': 'uppercase' },
        hide: "true"
      },
      { headerName: "Last Name", field: "LastName", width: 130, cellStyle: { color: "#ffff",'text-transform': 'uppercase' }, },
      { headerName: "First Name", field: "FirstName", width: 130, cellStyle: { color: "#ffff",'text-transform': 'uppercase' } },
      { headerName: "Middle Name", field: "MiddleName", width: 60, cellStyle: { color: "#ffff",'text-transform': 'uppercase' }, columnGroupShow: "open" },
      {
        headerName: "SSN (Last 5)", field: "SSN", width: 100, cellStyle: { color: "#ffff", 'text-transform': 'uppercase' },
        cellRenderer: (data) => {
          if (data.value) {
            const data1 = data.value.split('-').join('');
            return data1.slice(-5);
          }
        }
      },
      { 
        headerName: "Resource Type", 
        field: "ResourceType", 
        width: 110, 
        cellRenderer: (data) => {
          if (data) {
            let resourceType = this.commonService.getPickListData(
              "TransportationPeople",
              "ResourceType"
            ).filter((e) => e.PickListID == data.value)
              .map((e) => e.DisplayValue)[0];
            return resourceType ? resourceType : null;
          }
        },
        cellStyle: { color: "#ffff",'text-transform': 'uppercase' } 
      },
      {
        headerName: "Birth Date", field: "BirthDate",
        valueGetter: (params) => {
          return (params.data.BirthDate === '0000-00-00 00:00:00' || !params.data.BirthDate || params.data.BirthDate === '') ? ' ' : moment(params.data.BirthDate).format('MM/DD/YYYY');
        },
        width: 100, cellStyle: { color: "#ffff",'text-transform': 'uppercase' }, columnGroupShow: "open"
      }
    ]
  },
  {
    headerName: "Local 399 Grouping", field: "Local399Grouping", width: 140, cellStyle: { color: "#ffff",'text-transform': 'uppercase' },
    cellRenderer: (data) => {
      if (data) {
        let localGrouping = this.commonService.getPickListData(
          "TransportationPeople",
          "Local399Grouping"
        ).filter((e) => e.PickListID == data.value)
          .map((e) => e.DisplayValue)[0];
        return localGrouping ? localGrouping : null;
      }
    }
  },
  {
    headerName: "Status", field: "Status", width: 90, cellStyle: { color: "#ffff",'text-transform': 'uppercase' },
    cellRenderer: (data) => {
      if (data) {
        let status = this.commonService.getPickListData(
          "TransportationPeople",
          "Status"
        ).filter((e) => e.PickListID == data.value)
          .map((e) => e.DisplayValue)[0];
        return status ? status : null;
      }
    }
  },
  {
    headerName: "Employee's License Details",
    children: [
      { headerName: "DL Number", field: "DLNumber", width: 100, cellStyle: { color: "#ffff",'text-transform': 'uppercase' } },
      {
        headerName: "DL State", field: "DLState",
        cellRenderer: (data) => {
          if (data) {
            let dlstate = this.commonService.getPickListData(
              "TransportationPeople",
              "DLState"
            ).filter((e) => e.PickListID == +data.value)
              .map((e) => e.DisplayValue)[0];
            return dlstate ? dlstate : null;
          }
        }, width: 80, cellStyle: { color: "#ffff",'text-transform': 'uppercase' }
      },
      {
        headerName: "DL Expiration",
        field: "DLExpirationDate",
        width: 100,
        columnGroupShow: "open",
        cellStyle: { color: "#ffff",'text-transform': 'uppercase' },
        valueGetter: (params) => {
          return (params.data.DLExpirationDate === '0000-00-00 00:00:00' || !params.data.DLExpirationDate || params.data.DLExpirationDate === '') ? ' ' : moment(params.data.DLExpirationDate).format('MM/DD/YYYY');
        },
      },
      {
        headerName: "DL Classification",
        field: "DLClassification",
        cellRenderer: (data) => {
          if (data) {
            let dlclasification = this.commonService.getPickListData(
              "TransportationPeople",
              "DLClassification"
            ).filter((e) => e.PickListID == +data.value)
              .map((e) => e.DisplayValue)[0];
            return dlclasification ? dlclasification : null;
          }
        },
        width: 120,
        columnGroupShow: "open", cellStyle: { color: "#ffff",'text-transform': 'uppercase' }
      },
      {
        headerName: "DL Endorsements",
        field: "DLEndorsementName",
        width: 160,
        cellStyle: { color: "#ffff",'text-transform': 'uppercase' },
        autoHeight: true,
        columnGroupShow: "open",
      },
      {
        headerName: "DL Restrictions",
        field: "DLRestrictionsName",
        width: 90,
        columnGroupShow: "open",
        cellStyle: { color: "#ffff",'text-transform': 'uppercase' }
      },
    ],
  },

  {
    headerName: "Emergency Contact",
    children: [
      { headerName: "Emergency Name", field: "EmgContactName", width: 160, cellStyle: { color: "#ffff",'text-transform': 'uppercase' }, hide:"true"},
      {
        headerName: "Emergency Relationship",
        field: "EmgContactRelationship",
        width: 160,
        columnGroupShow: "open", cellStyle: { color: "#ffff",'text-transform': 'uppercase' },
        hide:"true"
      },
      {
        headerName: "Emergency Phone",
        field: "EmgContactPhone",
        width: 125,
        columnGroupShow: "open", cellStyle: { color: "#ffff",'text-transform': 'uppercase' },
        hide:"true"
      },
    ],
  },
  {
    headerName: "Medical Cert Expiration",
    field: "DLMedCertExpirationDt",
    valueGetter: (params) => {
      return (params.data.DLMedCertExpirationDt === '0000-00-00 00:00:00' || !params.data.DLMedCertExpirationDt || params.data.DLMedCertExpirationDt === '') ? ' ' : moment(params.data.DLMedCertExpirationDt).format('MM/DD/YYYY');
    },
    // cellRenderer: (data) => {
    //   const formattedDate = this.datePipe.transform(data.value, "MM/dd/yyyy");
    //   return formattedDate ? formattedDate : null;
    // },
    width: 150, cellStyle: { color: "#ffff",'text-transform': 'uppercase' },
  },
  {
    headerName: "Hire Date", field: "HireDate",
    valueGetter: (params) => {
      return (params.data.HireDate === '0000-00-00 00:00:00' || !params.data.HireDate || params.data.HireDate === '') ? ' ' : moment(params.data.HireDate).format('MM/DD/YYYY');
    },
    // cellRenderer: (data) => {
    //   const formattedDate = this.datePipe.transform(data.value, "MM/dd/yyyy");
    //   return formattedDate ? formattedDate : null;
    // },
    width: 100, cellStyle: { color: "#ffff",'text-transform': 'uppercase' }
  },
  {
    headerName: "Self Certification",
    field: "SelfCertification",
    cellRenderer: (data) => {
      if (data) {
        let selfcertification = this.commonService.getPickListData(
          "TransportationPeople",
          "SelfCertification"
        ).filter((e) => e.PickListID == +data.value)
          .map((e) => e.DisplayValue)[0];
        return selfcertification ? selfcertification : null;
      }
    },
    width: 100,
    cellStyle: { color: "#ffff",'text-transform': 'uppercase' }
  },
  // { headerName: "Last Worked", field: "LastWorked", 
  // valueGetter: (params) => {
  //   return (params.data.LastWorked === '0000-00-00 00:00:00' || !params.data.LastWorked || params.data.LastWorked === '') ? ' ' : moment(params.data.LastWorked).format('MM/DD/YYYY');
  // },
  // // cellRenderer: (data) => {
  // //   const formattedDate = this.datePipe.transform(data.value, "MM/dd/yyyy");
  // //   return formattedDate ? formattedDate : null;
  // // },
  //  width: 100 ,cellStyle: { color: "#ffff" }},
  {
    headerName: "WBSF/WBTV/ Pull Notice Date",
    field: "PullNoticeDate",
    valueGetter: (params) => {
      return (params.data.PullNoticeDate === '0000-00-00 00:00:00' || params.data.PullNoticeDate === 'Invalid date' || !params.data.PullNoticeDate || params.data.PullNoticeDate === '') ? ' ' : moment(params.data.PullNoticeDate).format('MM/DD/YYYY');
    },
    // cellRenderer: (data) => {
    //   const formattedDate = this.datePipe.transform(data.value, "MM/dd/yyyy");
    //   return formattedDate ? formattedDate : null;
    // },
    width: 190, 
    cellStyle: { color: "#ffff",'text-transform': 'uppercase' }
  },
  {
    headerName: "Annual Review Date", field: "AnnualReviewDate",
    valueGetter: (params) => {
      return (params.data.AnnualReviewDate === '0000-00-00 00:00:00' || !params.data.AnnualReviewDate || params.data.AnnualReviewDate === '') ? ' ' : moment(params.data.AnnualReviewDate).format('MM/DD/YYYY');
    },
    //  cellRenderer: (data) => {
    //   const formattedDate = this.datePipe.transform(data.value, "MM/dd/yyyy");
    //   return formattedDate ? formattedDate : null;
    // },
    width: 150, cellStyle: { color: "#ffff",'text-transform': 'uppercase' }
  },

  ];

  constructor(
    public dialog: MatDialog,
    private http: HttpClient,
    private router: Router,
    protected authService: AuthService,
    protected searchService: SearchService,
    protected userService: UserService,
    protected commonService: CommonService,
    protected notificationService: NotificationService,
    protected driverService: DriverService,
    protected datePipe: DatePipe,
    protected dialogService: DialogService,
    protected spinnerService: SpinnerService
  ) {
    this.selectedApp = 1;
    this.frameworkComponents = {
      profileRenderer: ProfileRenderer,
    };
    this.gridOptions = {
      getRowClass: (params) =>{
        if(params.data.Active === 0){
          return 'row-inactive';
        }
      },
      
    }
    this.defaultColDef = {
      resizable: true,
  }
    this.userApplications = [...this.userService.userApplications];  
  }

  onToggle(event) {
    this.activeStatusPickListId  = this.statusList.find(value => value.DisplayValue == "Active")
    if (event.checked === true) {
      this.rowData.forEach((data) => {
        data.PullNoticeDate = moment(data.PullNoticeDate).format('YYYY-MM-DD');
      })
      this.rowData = this.rowData.filter(row => (row.Status === this.activeStatusPickListId.PickListID ) &&
        ((row.PullNoticeDate == "0000-00-00 00:00:00") || (moment(row.PullNoticeDate).isBefore(this.lastYearSameDate))))

    } else {
      this.rowData = this.data;
    }
  }

  onApplicationChange() {
    // var selectedApplication = this.userApplications.filter((f)=>f.ApplicationID == this.selectedApp)[0].Name;
    //  this.searchService.setPageName(selectedApplication);
    this.searchService.switchApplication(this.selectedApp);
  }

  //when checkbox is checked, updates row data
  onSelectionChanged($event) {
    const selectedRows: Driver[] = $event.api.getSelectedRows();
    if (selectedRows.length) {
      this.selectedDriver = selectedRows[0];
      this.isRowSelected = true;
    } else {
      this.isRowSelected = false;
      this.isActive = true;
    }
    if (selectedRows.length) {
    if (selectedRows[0].Active) {
      this.isActive = true;
    } else {
      this.isActive = false;
    }
  }
    this.driverService.getTransportationPeople(this.selectedDriver.TransportationPeopleID).then(() =>{
      this.driver = this.driverService.driver[0];
      this.driverLoaded = true;
    });
  }
  //opens edit modal, send row data to modal
  updateRecord() {
    if (this.gridApi.getSelectedRows() != "") {
      const dialogRef = this.dialog.open(UpdateDriverModalComponent, {
        disableClose: true,
        data: this.selectedDriver
      }).afterClosed().subscribe(res => {
        this.isRowSelected = false;
        this.isActive = true;
      });
    }
  }
  //opens new pass modal, sends data
  createRecord() {
    this.gridApi.deselectAll();
    var count = this.gridApi.getDisplayedRowCount();
    var rowId = count + 1;
    const dialogNew = this.dialog.open(CreateDriverModalComponent, {
      data: {},
    });
  }
  //delete selected row
  deleteDriver() {
    if (this.gridApi.getSelectedRows().length) {
      this.dialogService
        .openConfirmDialog(
          "Confirm Transportation People Delete",
          "Are you sure you want to delete selected driver ?"
        )
        .afterClosed()
        .subscribe((confirm: boolean) => {
          if (confirm) {
            this.driver.Status = this.inactiveStatusID;
            this.driverService.deleteDriver(
              this.driver
            ).then(() => {
              this.isRowSelected = false;
              this.isActive = true;
            })
            .catch((err) => {
              this.notificationService.failure(err);
            });
          }
        });
    }
  }
  //open View
  openDashbaord($event) {
    var selected: Driver = $event.data;
    this.dialog.open(ViewDriverModalComponent, {});
  }
  //make ag-rid colums fill their container
  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }
  //reactivate and update record
  reactivateDriver() {
    if (this.gridApi.getSelectedRows().length) {
      const body = this.driver;
      this.dialogService
        .openConfirmDialog(
          "Confirm Transportation People Re-activate",
          "Are you sure you want to reactivate the driver and switch to edit mode ?"
        )
        .afterClosed()
        .subscribe((data: boolean) => {
          if (data) {
            body.Active = '1';
            body.Status = this.activeStatusID;
            var photoCount = 0;
          var attachmentCount = 0;
          this.driver.PhotosArray = [];
          this.driver.AttachmentsArray = [];
          this.newPhotoArray = [];
          this.newAttachmentArray = [];

            this.photoArray.forEach((element) => {
              element.Index = photoCount;
              element.LogicalFileName = element.BaseName + "." + element.Ext;
              photoCount++;
            });
            this.attachmentArray.forEach((element) => {
              element.Index = attachmentCount;
              attachmentCount++;
            });
            this.photoArray.forEach((element) => {
              if (
                element.PhotoID > 0 ||
                (element.PhotoID <= 0 && element.Active)
              ) {
                this.driver.PhotosArray.push(element);
              }
            });
            this.photoArray.forEach((element) => {
              if (element.PhotoID <= 0 && element.Active) {
                this.newPhotoArray.push(element);
              }
            });
            this.attachmentArray.forEach((element) => {
              if (
                element.AttachmentID > 0 ||
                (element.AttachmentID <= 0 && element.Active)
              ) {
                this.driver.AttachmentsArray.push(element);
              }
            });
  
            this.attachmentArray.forEach((element) => {
              if (element.AttachmentID <= 0 && element.Active) {
                this.newAttachmentArray.push(element);
              }
            });

            this.driverService
              .updateDriver(
                body,
                body.PhotosArray,
                body.AttachmentsArray
              )
              .then(() => {
                const dialogRef = this.dialog.open(UpdateDriverModalComponent, {
                  disableClose: true,
                  data: body,
                }).afterClosed().subscribe(res => {
                  this.isRowSelected = false;
                  this.isActive = true;
                })
              })
              .catch((err: HttpErrorResponse) =>
                this.notificationService.failure(err.message)
              );
          }
        });
    }
  }

  //get grid
  ngOnInit() {
    this.spinnerService.setIsLoading(true);

    this.searchService.setPageName("Transportation People");
    if (
      this.commonService.pickList != undefined &&
      this.commonService.pickList.filter(
        (f) => f.TableName === this.searchService.getPageName()
      ).length > 0
    ) {
      this.localGroupingList = this.commonService.getPickListData(
        this.searchService.getTableName(),
        "Local399Grouping"
      );
      this.statusList = this.commonService.getPickListData(
        this.searchService.getTableName(),
        "Status"
      );
      this.inactiveStatusID = this.statusList.filter(e => e.DisplayValue == 'Inactive').map(ele => ele.PickListID)[0];
      this.activeStatusID = this.statusList.filter(e => e.DisplayValue == 'Active').map(ele => ele.PickListID)[0];
      this.localGroupingList.unshift(new PickList());
      this.statusList.unshift(new PickList());
    } else {
      this.commonService
        .getPickListApi("TransportationPeople")
        .then(() => {
          if (
            this.commonService.pickList != undefined &&
            this.commonService.pickList.length > 0
          ) {
            this.localGroupingList = this.commonService.getPickListData(
              this.searchService.getTableName(),
              "Local399Grouping"
            );
            this.statusList = this.commonService.getPickListData(
              this.searchService.getTableName(),
              "Status"
            );
            this.inactiveStatusID = this.statusList.filter(e => e.DisplayValue == 'Inactive').map(ele => ele.PickListID)[0];
            this.activeStatusID = this.statusList.filter(e => e.DisplayValue == 'Active').map(ele => ele.PickListID)[0];
            this.localGroupingList.unshift(new PickList());
            this.statusList.unshift(new PickList());
          }
        })
        .catch((err) => {
          this.notificationService.failure(err);
        });
    }
    this.searchService
      .smartSearchAPI(null)
      .then(() => {
        this.toggle.checked = false;
        this.data = this.searchService.searchRecords;
       // this.data = this.data.filter((f) => f.Active)
        if (this.data) {
          this.data.forEach(element => {
            var photoArray: Photos[] = this.setPhotoArray(element.PhotoIDs, element.PhotoXIDs, element.PhotoS3KeyNames, element.PhotoDescriptions, element.PhotoLogicalFileNames, element.PhotoActives, element.PrimaryPhotoID);
            element.PrimaryPhotoArray = [];
            if (photoArray) {
              var primaryPhotoArray: Photos[] = photoArray.filter((f) => f.IsPrimary == true); //get PrimaryPhoto Array
              element.PrimaryPhotoArray = primaryPhotoArray;
            }
            element.PhotosArray = photoArray;
            element.AttachmentsArray = this.setAttachmentArray(element.AttachmentIDs, element.AttachmentXIDs, element.AttachmentS3KeyNames, element.AttachmentDescriptions, element.AttachmentLogicalFileNames, element.AttachmentActives);
            element.RecordID = element.TransportationPeopleID;
          });


          if (this.data) {
            this.commonService.getPrimaryPhotoFiles(this.data).then(() => {
              this.commonService.primaryPhotoFileRecords.forEach(element => {
                if (element.Photos.length > 0) {
                  this.data.filter((f) => f.TransportationPeopleID == element.RecordID)[0].PrimaryPhotoArray[0].SignedURLThumbnail = element.Photos[0].SignedURLThumbnail;
                  this.data.filter((f) => f.TransportationPeopleID == element.RecordID)[0].PrimaryPhotoArray[0].SignedURL = element.Photos[0].SignedURL;
                }
              });

            }).catch((err) => {
              this.notificationService.failure(err.msg);
            }).finally(() => {

              this.rowData = this.data;
            });
          }

        }
        this.spinnerService.setIsLoading(false);
      }).catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
        this.spinnerService.setIsLoading(false);
      });

    this.driverService.driverDataChanged.subscribe(
      (dataChanged: boolean) => {
        if (dataChanged) {
          this.searchService
            .smartSearchAPI(null)
            .then(() => {
              this.toggle.checked = false;
              this.data = this.searchService.searchRecords;
              //this.data = this.data.filter((f) => f.Active)
              if (this.data) {
                this.data.forEach(element => {
                  var photoArray: Photos[] = this.setPhotoArray(element.PhotoIDs, element.PhotoXIDs, element.PhotoS3KeyNames, element.PhotoDescriptions, element.PhotoLogicalFileNames, element.PhotoActives, element.PrimaryPhotoID);
                  element.PrimaryPhotoArray = [];
                  if (photoArray) {
                    var primaryPhotoArray: Photos[] = photoArray.filter((f) => f.IsPrimary == true); //get PrimaryPhoto Array
                    element.PrimaryPhotoArray = primaryPhotoArray;
                  }
                  element.PhotosArray = photoArray;
                  element.AttachmentsArray = this.setAttachmentArray(element.AttachmentIDs, element.AttachmentXIDs, element.AttachmentS3KeyNames, element.AttachmentDescriptions, element.AttachmentLogicalFileNames, element.AttachmentActives);
                  element.RecordID = element.TransportationPeopleID;
                });


                if (this.data) {
                  this.commonService.getPrimaryPhotoFiles(this.data).then(() => {
                    this.commonService.primaryPhotoFileRecords.forEach(element => {
                      if (element.Photos.length > 0) {
                        this.data.filter((f) => f.TransportationPeopleID == element.RecordID)[0].PrimaryPhotoArray[0].SignedURLThumbnail = element.Photos[0].SignedURLThumbnail;
                        this.data.filter((f) => f.TransportationPeopleID == element.RecordID)[0].PrimaryPhotoArray[0].SignedURL = element.Photos[0].SignedURL;
                      }
                    });

                  }).catch((err) => {
                    this.notificationService.failure(err.msg);
                  }).finally(() => {
                    this.rowData = this.data;
                  });
                }

              }
              this.spinnerService.setIsLoading(false);

            }).catch((err: HttpErrorResponse) => {
              this.notificationService.failure(err.message);
              this.spinnerService.setIsLoading(false);
            });

        }
      });

    this.searchService.searchInitiated.subscribe((eventData: boolean) => {
      if (eventData) {
        this.searchService.smartSearchPromise
          .then(() => {
            this.toggle.checked = false;
            this.data = this.searchService.searchRecords;
           // this.data = this.data.filter((f) => f.Active)
            if (this.data) {
              this.data.forEach(element => {
                var photoArray: Photos[] = this.setPhotoArray(element.PhotoIDs, element.PhotoXIDs, element.PhotoS3KeyNames, element.PhotoDescriptions, element.PhotoLogicalFileNames, element.PhotoActives, element.PrimaryPhotoID);
                element.PrimaryPhotoArray = [];
                if (photoArray) {
                  var primaryPhotoArray: Photos[] = photoArray.filter((f) => f.IsPrimary == true); //get PrimaryPhoto Array
                  element.PrimaryPhotoArray = primaryPhotoArray;
                }
                element.PhotosArray = photoArray;
                element.AttachmentsArray = this.setAttachmentArray(element.AttachmentIDs, element.AttachmentXIDs, element.AttachmentS3KeyNames, element.AttachmentDescriptions, element.AttachmentLogicalFileNames, element.AttachmentActives);
                element.RecordID = element.TransportationPeopleID;
              });


              if (this.data) {
                this.commonService.getPrimaryPhotoFiles(this.data).then(() => {
                  this.commonService.primaryPhotoFileRecords.forEach(element => {
                    if (element.Photos.length > 0) {
                      if (element.RecordID !== undefined) {
                        this.data.filter((f) => f.TransportationPeopleID == element.RecordID)[0].PrimaryPhotoArray[0].SignedURLThumbnail = element.Photos[0].SignedURLThumbnail;
                        this.data.filter((f) => f.TransportationPeopleID == element.RecordID)[0].PrimaryPhotoArray[0].SignedURL = element.Photos[0].SignedURL;
                      }
                    }
                  });

                }).catch((err) => {
                  this.notificationService.failure(err.msg);
                }).finally(() => {

                  this.rowData = this.data;
                });
              }

            }
            this.spinnerService.setIsLoading(false);
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
            this.spinnerService.setIsLoading(false);
          });
      }
    });

  }
  onGridReady(params) {
    this.gridApi = params.api;

    setTimeout(() => {
      params.api.showLoadingOverlay();
    }, 0);
    params.api.hideOverlay();
  }
  // switch Router for application

  // opens view driver modal on grid row click
  viewDriver(event: any) {
    this.clickedDriver = event.data;
    this.dialog.open(ViewDriverModalComponent, {
      data: this.clickedDriver,
    });
  }

  setPhotoArray(PhotoIDs: string, PhotoXIDs: string, PhotoS3KeyNames: string, PhotoDescriptions: string, PhotoLogicalFileNames: string, PhotoActives: string, PrimaryPhotoID: number): Photos[] {
    var photosArray: Photos[] = [];

    if (PhotoIDs == null) {
      return photosArray;
    } else {
      var photoIdArray = PhotoIDs.split(",");
      var photoS3KeyNamesArray = PhotoS3KeyNames.split(",");
      var photoDescriptionsArray = PhotoDescriptions.split(",");
      var photoLogicalFileNamesArray = PhotoLogicalFileNames.split(",");
      var photoActivesArray = PhotoActives.split(",");
      var photoXID = PhotoXIDs.split(",");
      var index: number = 0;
      photoIdArray.forEach(element => {
        var photo: Photos = {
          "XID": photoXID[index], "File": null, "ThumbnailFile": null, "PhotoID": Number(photoIdArray[index]), Active: Boolean(Number(photoActivesArray[index])), Description: photoDescriptionsArray[index]
          , SignedURL: "",
          SignedURLThumbnail: "",
          LogicalFileName: photoLogicalFileNamesArray[index],
          S3KeyName: photoS3KeyNamesArray[index],
          UserID: 1,
          Index: 0,
          IsPrimary: (Number(photoIdArray[index]) == PrimaryPhotoID) ? true : false,
          BaseName: photoLogicalFileNamesArray[index].replace(/\.[^/.]+$/, ""),
          Ext: photoLogicalFileNamesArray[index].substring(photoLogicalFileNamesArray[index].lastIndexOf('.') + 1, photoLogicalFileNamesArray[index].length) || "",

        }
        index++;
        photosArray.push(photo)
      });
      return photosArray;
    }
  }

  setAttachmentArray(AttachmentIDs: string, AttachmentXIDs: string, AttachmentS3KeyNames: string, AttachmentDescriptions: string, AttachmentLogicalFileNames: string, AttachmentActives: string): Attachments[] {
    var attachmentsArray: Attachments[] = [];

    if (AttachmentIDs == null) {
      return attachmentsArray;
    } else {
      var attachmentIdArray = AttachmentIDs.split(",");
      var attachmentS3KeyNamesArray = AttachmentS3KeyNames.split(",");
      var attachmentDescriptionsArray = AttachmentDescriptions.split(",");
      var attachmentLogicalFileNamesArray = AttachmentLogicalFileNames.split(",");
      var attachmentActivesArray = AttachmentActives.split(",");
      var attcahmnetXidsArray = AttachmentXIDs.split(",")
      var index: number = 0;
      attachmentIdArray.forEach((element) => {
        var attachment: Attachments = {
          XID: attcahmnetXidsArray[index],
          AttachmentID: Number(attachmentIdArray[index]),
          Active: Boolean(Number(attachmentActivesArray[index])),
          Description: attachmentDescriptionsArray[index],
          LogicalFileName: attachmentLogicalFileNamesArray[index],
          S3KeyName: attachmentS3KeyNamesArray[index],
          UserID: 1,
          Path: "",
          SignedURL: "",
          File: null,
          Index: 0,
          BaseName: attachmentLogicalFileNamesArray[index].replace(/\.[^/.]+$/, ""),
          Ext: attachmentLogicalFileNamesArray[index].substring(attachmentLogicalFileNamesArray[index].lastIndexOf('.') + 1, attachmentLogicalFileNamesArray[index].length) || "",
          OrginalFileName: attachmentLogicalFileNamesArray[index]
        };
        index++;
        attachmentsArray.push(attachment);
      });
      return attachmentsArray;
    }
  }

  setAliasArray(AliasIDs: string, AliasNames: string, AliasActives: string) {
    var aliasArray: Array<{
      Active: number;
      AliasID: number;
      Name: string;
      UserID: number;
    }> = [];

    if (AliasIDs == null) {
      return aliasArray;
    } else {
      var aliasIdArray = AliasIDs.split(",");
      var aliasNamesArray = AliasNames.split(",");
      var aliasActivesArray = AliasActives.split(",");
      var index: number = 0;
      aliasIdArray.forEach((element) => {
        var alias = {
          Active: Number(aliasActivesArray[index]),
          AliasID: Number(aliasIdArray[index]),
          Name: aliasNamesArray[index],
          UserID: 1,
        };
        index++;
        aliasArray.push(alias);
      });
      return aliasArray
    }
  }
  formatDate(params: any) {
    if (params.value) {
      const formattedDate = this.datePipe.transform(params.value, "yyyy-MM-dd");
      return formattedDate ? formattedDate : null;
    }
  }

  // open search filter modal
  onFilterBtnClick() {
    const dialogRef = this.dialog.open(SearchFilterModalComponent, {
      disableClose: false,
      data: { pageName: this.searchService.getPageName() },
      backdropClass: "backdropBackground",
    });
  }

  onSearchBtnClick() {
    const key = this.searchField.nativeElement.value.trim()
      ? this.searchField.nativeElement.value.trim()
      : null;
    this.searchService.smartSearchAPI(key);
    this.searchService.searchInitiated.emit(true);
  }
}
