import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { NewRecordComponent } from "../new-record/new-record.component";
import { EditRecordComponent } from "../edit-record/edit-record.component";
import * as _moment from "moment";
import { default as _rollupMoment } from "moment";
import { Router } from "@angular/router";
import { CreatePersonOfInterestModalComponent } from "../person-of-interest/modals/create-person-of-interest/create-person-of-interest-modal.component";
import { UpdatePersonOfInterestModalComponent } from "../person-of-interest/modals/update-person-of-interest/update-person-of-interest-modal.component";
import { PersonOfInterest } from "../models/person-of-interest.model";
import { ViewPersonOfInterestModalComponent } from "../person-of-interest/modals/view-person-of-interest/view-person-of-interest-modal.component";
import { AuthService } from "../services/auth.service";
import { SearchService } from "../services/search.service";
const moment = _rollupMoment || _moment;

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  rowData: any;
  params: [];
  private gridApi;
  protected selectedApp: number;
  protected userApplications: Array<{
    ApplicationID: number;
    Name: string;
  }> = [
    { ApplicationID: 1, Name: "Driver" },
    { ApplicationID: 2, Name: "Person Of Interest" },
  ];

  //grid data variables for selected row
  rsrcCode: any;
  rsrcLastname: any;
  rsrcFirstname: any;
  rsrcMi: any;
  rsrcSsn: any;
  rsrcBirthdate: any;
  rsrcResourcetype: any;
  rsrcGrouping: any;
  rsrcStatus: any;
  dlsNumber: any;
  dlsState: any;
  dlsExpiration: any;
  dlsClassification: any;
  dlsEndorsements: any;
  dlsRestrictions: any;
  rsrcAddress: any;
  rsrcCity: any;
  rsrcState: any;
  rsrcZip: any;
  rsrcAddresstwo: any;
  rsrcCellphone: any;
  rsrcHomephone: any;
  rsrcEmail: any;
  emrgncName: any;
  emrgncPhone: any;
  emrgncRelationship: any;
  rsrcHire: any;
  rsrcSelfcert: any;
  rsrcLastworked: any;
  rsrcWbsf: any;
  rsrcComments: any;
  rsrcReview: any;
  healthCertexp: any;

  //ag-grid
  columnDefs = [
    {
      headerName: "Resource Code",
      field: "resourceCode",
      width: 110,
      checkboxSelection: true,
    },
    { headerName: "Last Name", field: "lastName", width: 130 },
    { headerName: "First Name", field: "firstName", width: 130 },
    { headerName: "MI", field: "mi", width: 60 },
    { headerName: "SSN", field: "ssn", width: 100 },
    { headerName: "Birth Date", field: "birthdate", width: 100 },
    { headerName: "Local 399 Grouping", field: "localGrouping", width: 140 },
    { headerName: "Status", field: "status", width: 90 },
    {
      headerName: "Employee's License Details",
      children: [
        { headerName: "DL Number", field: "dlNumber", width: 100 },
        { headerName: "DL State", field: "dlState", width: 80 },
        {
          headerName: "DL Expiration",
          field: "dlExpiration",
          width: 100,
          columnGroupShow: "open",
        },
        {
          headerName: "DL Classification",
          field: "dlClassification",
          width: 120,
          columnGroupShow: "open",
        },
        {
          headerName: "Endorsements",
          field: "endorsements",
          width: 160,
          cellStyle: { "white-space": "normal" },
          autoHeight: true,
          columnGroupShow: "open",
        },
        {
          headerName: "Restrictions",
          field: "restrictions",
          width: 90,
          columnGroupShow: "open",
        },
      ],
    },
    {
      headerName: "Address",
      children: [
        { headerName: "Address", field: "address" },
        {
          headerName: "City",
          field: "city",
          width: 120,
          columnGroupShow: "open",
        },
        {
          headerName: "State",
          field: "state",
          width: 70,
          columnGroupShow: "open",
        },
        {
          headerName: "Zip Code",
          field: "zip",
          width: 100,
          columnGroupShow: "open",
        },
        {
          headerName: "Address Two",
          field: "addressTwo",
          columnGroupShow: "open",
        },
      ],
    },
    {
      headerName: "Contact",
      children: [
        { headerName: "Cell Phone", field: "cellPhone", width: 125 },
        {
          headerName: "Home Phone",
          field: "homePhone",
          width: 125,
          columnGroupShow: "open",
        },
        {
          headerName: "Email",
          field: "email",
          width: 140,
          columnGroupShow: "open",
        },
      ],
    },
    {
      headerName: "Emergency Contact",
      children: [
        { headerName: "Emergency Name", field: "emergencyName", width: 160 },
        {
          headerName: "Emergency Relationship",
          field: "emergencyRelationship",
          width: 160,
          columnGroupShow: "open",
        },
        {
          headerName: "Emergency Phone",
          field: "emergencyPhone",
          width: 125,
          columnGroupShow: "open",
        },
      ],
    },
    { headerName: "Medical Cert Expiration", field: "medicalExp", width: 150 },
    { headerName: "Hire Date", field: "hireDate", width: 100 },
    {
      headerName: "Self Certification",
      field: "SelfCertification",
      width: 100,
    },
    // { headerName: "Last Worked", field: "lastWorked", width: 100 },
    {
      headerName: "WBSF/WBTV/ Pull Notice Date",
      field: "wbsfWbtv",
      width: 190,
    },
    { headerName: "Annual Review Date", field: "annualReview", width: 150 },
    {
      headerName: "Notes",
      field: "notes",
      cellStyle: { "white-space": "normal" },
      autoHeight: true,
      width: 160,
    },
  ];

  constructor(
    public dialog: MatDialog,
    private http: HttpClient,
    private router: Router,
    protected authService: AuthService,
    protected searchService: SearchService
  ) {
    this.selectedApp = 1;
  }
  onApplicationChange() {
    console.log(this.selectedApp);
    var selectedApplication = this.userApplications.filter(
      (f) => f.ApplicationID == this.selectedApp
    )[0].Name;
    this.searchService.setPageName(selectedApplication);
  }
  //when checkbox is checked, updates row data
  onSelectionChanged() {
    if (this.gridApi.getSelectedRows() != "") {
      var selectedRows = this.gridApi.getSelectedRows();
      this.rsrcCode = selectedRows[0].resourceCode;
      this.rsrcLastname = selectedRows[0].lastName;
      this.rsrcFirstname = selectedRows[0].firstName;
      this.rsrcMi = selectedRows[0].mi;
      this.rsrcSsn = selectedRows[0].ssn;
      this.rsrcBirthdate = new Date(selectedRows[0].birthdate);
      this.rsrcResourcetype = selectedRows[0].resourceType;
      this.rsrcGrouping = selectedRows[0].localGrouping;
      this.rsrcStatus = selectedRows[0].status;
      this.dlsNumber = selectedRows[0].dlNumber;
      this.dlsState = selectedRows[0].dlState;
      this.dlsExpiration = new Date(selectedRows[0].dlExpiration);
      this.dlsClassification = selectedRows[0].dlClassification;
      this.dlsEndorsements = selectedRows[0].endorsements;
      this.dlsRestrictions = selectedRows[0].restrictions;
      this.rsrcAddress = selectedRows[0].address;
      this.rsrcCity = selectedRows[0].city;
      this.rsrcState = selectedRows[0].state;
      this.rsrcZip = selectedRows[0].zip;
      this.rsrcAddresstwo = selectedRows[0].addressTwo;
      this.rsrcCellphone = selectedRows[0].cellPhone;
      this.rsrcHomephone = selectedRows[0].homePhone;
      this.rsrcEmail = selectedRows[0].email;
      this.emrgncName = selectedRows[0].emergencyName;
      this.emrgncPhone = selectedRows[0].emergencyPhone;
      this.emrgncRelationship = selectedRows[0].emergencyRelationship;
      this.healthCertexp = new Date(selectedRows[0].medicalExp);
      this.rsrcHire = new Date(selectedRows[0].hireDate);
      this.rsrcSelfcert = selectedRows[0].SelfCertification;
      this.rsrcLastworked = new Date(selectedRows[0].lastWorked);
      this.rsrcWbsf = new Date(selectedRows[0].wbsfWbtv);
      this.rsrcReview = new Date(selectedRows[0].annualReview);
      this.rsrcComments = selectedRows[0].notes;
    }
  }
  //opens edit modal, send row data to modal
  updateRecord() {
    if (this.gridApi.getSelectedRows() != "") {
      const dialogRef = this.dialog.open(UpdatePersonOfInterestModalComponent, {
        disableClose: true,
        data: {
          resourceCode: this.rsrcCode,
          lastName: this.rsrcLastname,
          firstName: this.rsrcFirstname,
          mi: this.rsrcMi,
          ssn: this.rsrcSsn,
          birthdate: this.rsrcBirthdate,
          resourceType: this.rsrcResourcetype,
          localGrouping: this.rsrcGrouping,
          status: this.rsrcStatus,
          dlNumber: this.dlsNumber,
          dlState: this.dlsState,
          dlClassification: this.dlsClassification,
          dlExpiration: this.dlsExpiration,
          endorsements: this.dlsEndorsements,
          restrictions: this.dlsRestrictions,
          address: this.rsrcAddress,
          city: this.rsrcCity,
          state: this.rsrcState,
          zip: this.rsrcZip,
          addressTwo: this.rsrcAddresstwo,
          cellPhone: this.rsrcCellphone,
          homePhone: this.rsrcHomephone,
          email: this.rsrcEmail,
          emergencyName: this.emrgncName,
          emergencyPhone: this.emrgncPhone,
          emergencyRelationship: this.emrgncRelationship,
          medicalExp: this.healthCertexp,
          hireDate: this.rsrcHire,
          SelfCertification: this.rsrcSelfcert,
          lastWorked: this.rsrcLastworked,
          wbsfWbtv: this.rsrcWbsf,
          annualReview: this.rsrcReview,
          notes: this.rsrcComments,
        },
      });
      //update grid data on modal close and format date
      dialogRef.afterClosed().subscribe((data) => {
        var selectedRows = this.gridApi.getSelectedRows();
        var selectedNodes = this.gridApi.getSelectedNodes()[0];
        var selectedData = selectedNodes;
        var birthDate = moment(data.birthdate).format("MM/DD/YYYY");
        data.birthdate = birthDate;
        var dlExpires = moment(data.dlExpiration).format("MM/DD/YYYY");
        data.dlExpiration = dlExpires;
        var dateHired = moment(data.hireDate).format("MM/DD/YYYY");
        data.hireDate = dateHired;
        var workedLast = moment(data.lastWorked).format("MM/DD/YYYY");
        data.lastWorked = workedLast;
        selectedData.setData(data);
        this.gridApi.deselectAll();
      });
    }
  }
  //opens new pass modal, sends data
  createRecord() {
    this.gridApi.deselectAll();
    var count = this.gridApi.getDisplayedRowCount();
    var rowId = count + 1;
    const dialogNew = this.dialog.open(CreatePersonOfInterestModalComponent, {
      data: {},
    });
    //set data to add a new row
    dialogNew.afterClosed().subscribe((data) => {
      data.resourceCode = rowId;
      var newDate = moment(data.birthdate).format("MM/DD/YYYY");
      data.birthdate = newDate;
      var dlExpires = moment(data.dlExpiration).format("MM/DD/YYYY");
      data.dlExpiration = dlExpires;
      var dateHired = moment(data.hireDate).format("MM/DD/YYYY");
      data.hireDate = dateHired;
      var workedLast = moment(data.lastWorked).format("MM/DD/YYYY");
      data.lastWorked = workedLast;
      var arrTime = moment(data.arrTime).format("h:mm:ss a");
      data.arrTime = arrTime;
      this.gridApi.updateRowData({ add: [data] });
    });
  }
  //delete selected row
  onRemoveSelected() {
    var selectedData = this.gridApi.getSelectedRows();
    var res = this.gridApi.updateRowData({ remove: selectedData });
  }
  //open View
  openDashbaord($event) {
    var selected: PersonOfInterest = $event.data;
    this.dialog.open(ViewPersonOfInterestModalComponent, {});
  }
  //make ag-rid colums fill their container
  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  ngOnInit() {}
  onGridReady(params) {
    this.gridApi = params.api;
  }
  // switch Router for application
}
