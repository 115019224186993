import { Component, OnInit, Inject, DoCheck } from "@angular/core";

import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material";
import { DialogService } from "src/app/services/dialog.service";
import { PickList } from "src/app/models/pickList.model";
import { GridApi } from "ag-grid-community";
import { RequiredFieldsService } from "src/app/services/required-fields.service";
import { PersonOfInterestService } from "src/app/services/person-of-interest.service";
import { CommonService } from "src/app/services/common.service";
import { NotificationService } from "src/app/services/notification.service";
import { Base64Service } from "src/app/services/base64.service";
import { Driver } from 'src/app/models/driver.model ';
import { UpdateDriverModalComponent } from '../update-driver/update-driver-modal.component';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { DriverService } from "src/app/services/driver.service";
import { SpinnerService } from "src/app/services/spinner.service";
import { Address } from "src/app/models/address.model";
import { SearchService } from "src/app/services/search.service";
import { Notes } from "src/app/models/note.model";
import { Attachments } from "src/app/models/attachments.model";
import { HyperLinkComponent } from "src/app/hyperlink.component";

@Component({
  selector: "app-view-driver-modal",
  templateUrl: "./view-driver-modal.component.html",
  styleUrls: ["./view-driver-modal.component.css"],
})
export class ViewDriverModalComponent implements OnInit {
  protected step = 0;
  protected driver: Driver;
  protected genderList: PickList[];
  protected restrictedReasonList: PickList[];
  protected restrictedTypeList: PickList[];
  protected raceList: PickList[];
  protected hairList: PickList[];
  protected eyesList: PickList[];

  protected addedAliases: Array<String> = [];
  protected alias: string;
  protected restricted: boolean = false;
  protected isRestrictedReasonloading: boolean = false;
  protected isGenderLoading: boolean = false;
  protected gridApi: GridApi;
  protected defaultImageIndex = undefined;
  protected maxDate;
  protected signedURL;
  protected driverArray;
  protected addressRowData: Array<Address> = [];
  protected notesRowData: Array<Notes> = [];
  protected notesGridOptions: Object;
  protected rowClassRulesNotes;
  protected rowClassRules;
  attachmentArray: Array<Attachments> = [];
  rowData: Array<Attachments> = [];
  rowDataClicked = {};
  frameworkComponents: any;
  protected phonelist: any;
  protected phoneData: any[] = [];
  protected statusList: any;
  protected status: any;
  protected localGroupingList: any;
  protected localGrouping: any;
  protected resourcelist: any;
  protected resource: any;
  protected dlStatelist: any;
  protected dlState: any;
  protected dlClasificationlist: any;
  protected dlClassification: any;
  protected selfCertificationlist: any;
  protected selfCertification: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Driver,
    protected dialogRef: MatDialogRef<ViewDriverModalComponent>,
    protected dialog: MatDialog,
    protected requiredFieldService: RequiredFieldsService,
    protected personOfInterestService: PersonOfInterestService,
    protected commonService: CommonService,
    protected dialogService: DialogService,
    protected notificationService: NotificationService,
    protected base64Service: Base64Service,
    protected datePipe: DatePipe,
    protected driverService: DriverService,
    protected spinnerService: SpinnerService,
    protected searchService: SearchService
  ) {

    this.frameworkComponents = {
      hyperLinkComponent: HyperLinkComponent,
    };
    this.rowClassRules = {
      "row-ignore": (params) => {
        var condition = params.data.Default === true;
        return condition;
      }
    }

    this.rowClassRulesNotes = {
      "row-inactive": (params) => {
        var condition = params.data.Active === false;
        return condition;
      },
    };
    this.notesGridOptions = {
      autoSizeStrategy: {
        type: 'fitGridWidth',
        defaultMinWidth: 100
      }
    }
  }
  //get grid
  onGridReady(params) {
    this.gridApi = params.api;
  }
  notesColumnDefs = [

    {
      headerName: "Created Date",
      field: "CreatedDate",
      hide: false,
      cellRenderer: (data) => {
        const formattedDate = this.datePipe.transform(data.value, "MM/dd/yyyy h:mm a");
        return formattedDate ? formattedDate : null;
      },
      sortable: true,
      resizable: true,
      width: 90,
    },
    {
      headerName: "Created By",
      field: "CreatedBy",
      hide: false,
      sortable: true,
      resizable: true,
      width: 130,

    },
    {
      headerName: "Note",
      field: "Note",
      resizable: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { "white-space": "normal" }
    },

  ]
  addressColumnDefs = [
    {
      headerName: "Address Type",
      field: "AddressType",
      cellStyle: { color: "#000", 'text-transform': 'uppercase' },
      hide: false,
      sortable: true,
      cellRenderer: (data) => {
        if (data) {
          let addressType = this.commonService.getPickListData(
            this.searchService.getTableName(),
            "AddressType"
          ).filter((e) => e.PickListID == +data.value)
            .map((e) => e.DisplayValue)[0];
          return addressType ? addressType : null;
        }
      },
      resizable: true,
      width: 100,
    },
    {
      headerName: "Default",
      field: "Default",
      cellStyle: { 'text-transform': 'uppercase' },
      resizable: true,
      cellRenderer: (data) => {
        if (data.value) {
          return "Yes";
        } else {
          return "No";
        }
      },
      width: 70,
    },
    {
      headerName: "Address",
      field: "Address",
      cellStyle: { color: "#000", 'text-transform': 'uppercase' },
      hide: false,
      sortable: true,
      resizable: true,
      width: 267,
    },
    {
      headerName: "City",
      field: "City",
      hide: false,
      cellStyle: { 'text-transform': 'uppercase' },
      sortable: true,
      resizable: true,
      cellRenderer: (data) => {
        if (data) {
          let addressType = this.commonService.getPickListData(
            this.searchService.getTableName(),
            "City"
          ).filter((e) => e.PickListID == +data.value)
            .map((e) => e.DisplayValue)[0];
          return addressType ? addressType : null;
        }
      },
      width: 100,
    },
    {
      headerName: "State",
      field: "State",
      hide: false,
      cellStyle: { 'text-transform': 'uppercase' },
      sortable: true,
      resizable: true,
      cellRenderer: (data) => {
        if (data) {
          let addressType = this.commonService.getPickListData(
            this.searchService.getTableName(),
            "State"
          ).filter((e) => e.PickListID == +data.value)
            .map((e) => e.DisplayValue)[0];
          return addressType ? addressType : null;
        }
      },
      width: 100,
    },
    {
      headerName: "Zip",
      field: "Zip",
      hide: false,
      cellStyle: { 'text-transform': 'uppercase' },
      sortable: true,
      resizable: true,
      cellRenderer: (data) => {
        if (data) {
          let addressType = this.commonService.getPickListData(
            this.searchService.getTableName(),
            "Zip"
          ).filter((e) => e.PickListID == +data.value)
            .map((e) => e.DisplayValue)[0];
          return addressType ? addressType : null;
        }
      },
      width: 100,
    },
    {
      headerName: "Country",
      field: "Country",
      hide: false,
      cellStyle: { 'text-transform': 'uppercase' },
      sortable: true,
      resizable: true,
      cellRenderer: (data) => {
        if (data) {
          let addressType = this.commonService.getPickListData(
            this.searchService.getTableName(),
            "Country"
          ).filter((e) => e.PickListID == +data.value)
            .map((e) => e.DisplayValue)[0];
          return addressType ? addressType : null;
        }
      },
      width: 100,
    },

  ];
  columnDefs = [
    {
      headerName: "Name",
      field: "LogicalFileName",
      cellStyle: { color: "#000" },
      hide: false,
      sortable: true,
      resizable: true,
      width: 200,
    },
    {
      headerName: "Description",
      field: "Description",
      cellStyle: { color: "#000" },
      hide: false,
      sortable: true,
      resizable: true,
      width: 467,
    },
    {
      headerName: "",
      cellRenderer: "hyperLinkComponent",
      resizable: true,
      cellRendererParams: {
        onClick: this.onBtnClick.bind(this),
        label: "Remove",
      },
      width: 100,
    }
  ];

  onBtnClick(e) {
    this.rowDataClicked = e.rowData;
  }

  ngOnInit(): void {
    this.spinnerService.setIsLoading(true);
    this.driver = { ...this.data };
    console.log("Driver Data", this.driver)

    this.driver.BirthDate = this.formatDate(this.driver.BirthDate);
    this.driver.DLExpirationDate = this.formatDate(this.driver.DLExpirationDate);
    this.driver.AnnualReviewDate = this.formatDate(this.driver.AnnualReviewDate);
    this.driver.HireDate = this.formatDate(this.driver.HireDate);
    this.driver.PullNoticeDate = this.formatDate(this.driver.PullNoticeDate);
    this.driver.DLMedCertExpirationDt = this.formatDate(this.driver.DLMedCertExpirationDt);
    if (
      this.driver.PhotosArray.length > 0 ||
      this.driver.AttachmentsArray.length > 0
    ) {
      this.commonService
        .getAllFiles(
          this.driver.PhotosArray.filter((f) => f.Active),
          this.driver.AttachmentsArray.filter((f) => f.Active),
          Number(this.driver.TransportationPeopleID)
        )
    }
    if (this.driver.PrimaryPhotoArray.length > 0) {
      this.signedURL = this.driver.PrimaryPhotoArray[0].SignedURL;
    } else {
      this.signedURL = '../../../../assets/profile-picture/profileImage.png';
    }
    this.commonService
      .getPickListApi()
      .then(() => {
        if (
          this.commonService.pickList != undefined &&
          this.commonService.pickList.length > 0
        ) {
          this.genderList = this.commonService.getPickListData(
            "PersonOfInterest",
            "Gender"
          );
          this.raceList = this.commonService.getPickListData(
            "PersonOfInterest",
            "Race"
          );
          this.restrictedReasonList = this.commonService.getPickListData(
            "PersonOfInterest",
            "RestrictedReason"
          );
          this.restrictedTypeList = this.commonService.getPickListData(
            "PersonOfInterest",
            "RestrictionType"
          );
          this.phonelist = this.commonService.getPickListData(
            "TransportationPeople",
            "Phone"
          );
          this.statusList = this.commonService.getPickListData(
            "TransportationPeople",
            "Status"
          );
          this.localGroupingList = this.commonService.getPickListData(
            "TransportationPeople",
            "Local399Grouping"
          );
          this.resourcelist = this.commonService.getPickListData(
            "TransportationPeople",
            "ResourceType"
          );
          this.dlStatelist = this.commonService.getPickListData(
            "TransportationPeople",
            "DLState"
          );
          this.dlClasificationlist = this.commonService.getPickListData(
            "TransportationPeople",
            "DLClassification"
          );
          this.selfCertificationlist = this.commonService.getPickListData(
            "TransportationPeople",
            "SelfCertification"
          );
        }
      })
      .catch((err) => {
        this.notificationService.failure(err);
      });
    this.driverService.getTransportationPeople(this.driver.TransportationPeopleID).then(() => {
      this.spinnerService.setIsLoading(true);
      this.driverArray = this.driverService.driver[0];
      this.addressRowData = this.driverArray.TransportationPeopleAddress;
      this.notesRowData = this.driverArray.TransportationPeopleNote;
      this.rowData = this.driverArray.AttachmentsArray.filter((f) => f.Active);
      this.attachmentArray = this.driverArray.AttachmentsArray.filter((f) => f.Active);

      //for getting phone displayvalue from phonelist
      this.driverArray.TransportationPeoplePhone.forEach((ele) => {
        const matchingPhone = this.phonelist.find((ele2) => ele.PhoneType === ele2.PickListID);
        if (matchingPhone) {
          ele.DisplayValue = matchingPhone.DisplayValue;
        }
      });
      this.phoneData = this.driverArray.TransportationPeoplePhone;
      this.status = this.statusList.find(ele => ele.PickListID === this.driver.Status).DisplayValue;
      this.localGrouping = this.localGroupingList.find(ele => ele.PickListID === this.driver.Local399Grouping).DisplayValue;
      this.resource = this.resourcelist.find(ele => ele.PickListID === this.driver.ResourceType).DisplayValue;
      this.dlState = this.dlStatelist.find(ele => ele.PickListID === this.driver.DLState).DisplayValue;
      this.dlClassification = this.dlClasificationlist.find(ele => ele.PickListID === this.driver.DLClassification).DisplayValue;
      this.selfCertification = this.selfCertificationlist.find(ele => ele.PickListID === this.driver.SelfCertification).DisplayValue;

      this.spinnerService.setIsLoading(false);
    }).catch((err) => {
      this.notificationService.failure(err.msg);
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setStep(index: number): void {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  //close
  close() {
    this.onNoClick();
  }
  //opens edit modal, send row data to modal
  updateDriver() {
    this.onNoClick();
    this.dialog.open(UpdateDriverModalComponent, {
      data: this.driver,
    });
  }
  formatDate(date: string) {

    const formattedDate = (date === '0000-00-00 00:00:00' || date === '') ? '' : moment(date).format('MM/DD/YYYY');
    return formattedDate;
  }
  formatTime(date: string) {
    const formattedTime = (date === '0000-00-00 00:00:00' || date === '') ? '' : moment(date).format('DD/MM/YYYY h:mm A');
    return formattedTime;
  }
  panelExpanded() { }
}
