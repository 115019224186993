import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { UserService } from "../services/user.service";

@Injectable({
  providedIn: "root",
})
export class ApplicationGuard implements CanActivate {
  constructor(private userService: UserService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const path = route.url[0].path;

    console.log(path);
    console.log(this.userService.userAccessibleApplication[path]);
    localStorage["AddressFieldRequired"] = path == "transportationpeople" ? false : true;

    return this.userService.userAccessibleApplication[path];
  }
}
